.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiperSlide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiperSlide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .container {
    padding: 4rem 1rem;
  }
  
  .container p {
    margin: 1.5rem 0;
  }
  
  .card {
    max-width: 60rem;
    box-shadow: -10px 10px 80px rgba(255, 255, 255, 0.1);
    border: 1px solid #333;
    border-radius: 8px;
    padding: 2rem;
    margin: 0 auto;
  }
  
  .inlineCode {
    color: #be00ff;
    font-size: 16px;
    white-space: pre-wrap;
  }
  
  .inlineCode::before,
  .inlineCode::after {
    content: '`';
  }
  
  .hr {
    border: 0;
    border-top: 1px solid #333;
    margin: 1.5rem 0;
  }
  
  .bgWrap {
    position: fixed;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    z-index: -1;
  }
  
  .bgText {
    margin: 0;
    font-size: 2rem;
    line-height: 3rem;
    text-align: center;
    padding-top: 40vh;
    text-shadow: 1px 1px 1px #3c5c5e;
  }
  