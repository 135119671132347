@tailwind base;
@tailwind components;
@tailwind utilities;

.my-video {
  width: 100vw;
  height: 94vh;
  aspect-ratio: 16/9;
  object-fit: cover;
}

@layer base {
  html {
    font-family: "Rubik";
  }
}
.my-image {
  /*
    Give it explicit dimensions so that it takes up space
    even while the image is loading in
  */
  width: 600px;
  aspect-ratio: 5/8;

  /* Specify a background color */
  background: #ccc;

  /*
    You can also round the corners, or do whatever else
    you would like!
  */
  border-radius: 6px;
  overflow: hidden;
}

.ck-editor__editable {
  height: 185px;
  border-radius: 20px;
}

/* For Firefox Browser */
.scrollbar::-webkit-scrollbar {
  width: 20px;
  height: 10px;
}

.scrollbar::-webkit-scrollbar-track {
  border-radius: 100vh;
  margin-top: 10vh;
  margin-bottom: 10vh;
  background: #f7f4ed;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: #1acdce;
  border-radius: 100vh;
  border: 3px solid #f6f7ed;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #9ff291;
}

.container-box .box {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  width: 300px;
  height: 370px;
  background: #fff;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 10px;
  box-shadow: 0 8px 12px 0 rgba(1, 245, 245, 0.3);
  border: 2px solid #01c7c7;
}

.container-box .box::before {
  content: "";
  width: 100%;
  height: 100%;
  /* background: -webkit-linear-gradient(#42E5C3, #25CFDA); */
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 3px solid rgba(255, 255, 255, 0.18);
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.5s;
}

.container-box .box:hover:before {
  transform: scaleY(1);
  transform-origin: bottom;
  transition: transform 0.5s;
}

.container-box h2 {
  position: absolute;
  justify-content: center;
  align-items: center;
  display: flex;
  color: white;
  top: 220px;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
  z-index: 1;
  opacity: 1;
  transition: 0.9s;
}

.container-box .box:hover h2 {
  color: #000;
  opacity: 1;
  transform: translateY(-175px);
}

.container-box h3 {
  position: absolute;
  justify-content: center;
  align-items: center;
  display: flex;
  top: 250px;
  font-size: 12px;
  font-weight: 600;
  background: -webkit-linear-gradient(#01f5f5, #25beef);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Arial, Helvetica, sans-serif;
  z-index: 1;
  opacity: 1;
  transition: 0.9s;
}

.container-box .box:hover h3 {
  opacity: 1;
  color: #fff;
  transform: translateY(-234px);
}

.container-box .box:hover .bm-profile-img {
  transition: 0.4s;
  opacity: 0;
}

.bm-profile-img {
  position: relative;
}

.container-box p {
  font-family: Arial, Helvetica, sans-serif;
  position: absolute;
  left: 20px;
  top: 90px;
  font-size: 14px;
  z-index: 3;
  color: #fff;
  opacity: 0;
  transition: 0.9s;
}

.container-box .box:hover h3,
.container-box .box:hover p {
  color: #000;
  opacity: 1;
}

.container {
  padding: 4rem 1rem;
}

.container p {
  margin: 1.5rem 0;
}

.card {
  max-width: 60rem;
  box-shadow: -10px 10px 80px rgba(255, 255, 255, 0.1);
  border: 1px solid #333;
  border-radius: 8px;
  padding: 2rem;
  margin: 0 auto;
}

.inlineCode {
  color: #be00ff;
  font-size: 16px;
  white-space: pre-wrap;
}

.inlineCode::before,
.inlineCode::after {
  content: "`";
}

.hr {
  border: 0;
  border-top: 1px solid #333;
  margin: 1.5rem 0;
}

.text-transparent {
  color: transparent;
}

.bgText {
  margin: 0;
  font-size: 2rem;
  line-height: 3rem;
  text-align: center;
  padding-top: 40vh;
  text-shadow: 1px 1px 1px #3c5c5e;
}
